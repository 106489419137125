import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://spbe.solusibaik.com/app/api/v1',
  baseURL: process.env.VUE_APP_API_URL,
  // baseURL: 'https://ega.solusibaik.com/api/v2',
  // baseURL: 'https://192.168.77.213:5000/api/v2',
  // // timeout: 1000,
  // headers: {
  //   'Access-Control-Allow-Origin': '*',
  //   'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
  //   'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token, Authorization',
  // },
})

// Add a response interceptor
axiosIns.interceptors.response.use(
  response => response, async error => {
    if (error.response.status === 401) {
      localStorage.removeItem('userData')
      localStorage.removeItem('userRole')
      localStorage.removeItem('userToken')
      localStorage.removeItem('roleId')
      localStorage.removeItem('tahunAnggaran')
      window.location.href = '/login'

      return Promise.resolve(error.response)
    }
    return Promise.reject(error)
  },
)

const splpInstance = axios.create({
  baseURL: process.env.VUE_APP_SPLP_URL,
})
const authInstance = axios.create({
  baseURL: process.env.VUE_APP_SPLP_AUTH_URL,
})
const signInstance = axios.create({
  baseURL: process.env.VUE_APP_SPLP_SIGN_URL,
})

Vue.prototype.$http = axiosIns
Vue.prototype.$auth = authInstance
Vue.prototype.$splp = splpInstance
Vue.prototype.$sign = signInstance

export default axiosIns
