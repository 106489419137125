export default [{
  path: '/master/user',
  name: 'master-user',
  component: () => import('@/views/master-data/TemplateUser.vue'),
  meta: {
    pageTitle: 'Master Data Pengguna',
    breadcrumb: [
      {
        text: 'Master Data',
      },
      {
        text: 'Users',
        active: true,
      },
    ],
  },
},
{
  path: '/master/account',
  name: 'master-account',
  component: () => import('@/views/master-data/TemplateUserAccount.vue'),
  meta: {
    pageTitle: 'Master Data Grup Pengguna',
    breadcrumb: [
      {
        text: 'Master Data',
      },
      {
        text: 'User Account Group',
        active: true,
      },
    ],
  },
},
{
  path: '/master/roles',
  name: 'master-roles',
  component: () => import('@/views/master-data/TemplateUserRoles.vue'),
  meta: {
    pageTitle: 'Master Data Role Pengguna',
    breadcrumb: [
      {
        text: 'Master Data',
      },
      {
        text: 'User Roles',
        active: true,
      },
    ],
  },
},
{
  path: '/master/domain/proses-bisnis',
  name: 'master-domain-proses-bisnis',
  component: () => import('@/views/master-data/MasterDomainProsesBisnis.vue'),
  meta: {
    pageTitle: 'Master Data Domain Proses Bisnis',
    breadcrumb: [
      {
        text: 'Master Data',
      },
      {
        text: 'Domain',
      },
      {
        text: 'Proses Bisnis',
        active: true,
      },
    ],
  },
},
{
  path: '/master/domain/bisnis/tambah',
  name: 'master-domain-proses-bisnis-tambah',
  component: () => import('@/views/master-data/MasterDomainProsesBisnis.vue'),
  meta: {
    pageTitle: 'Master Data Domain Proses Bisnis',
    breadcrumb: [
      {
        text: 'Master Data',
      },
      {
        text: 'Domain',
      },
      {
        text: 'Proses Bisnis',
      },
      {
        text: 'Tambah',
        active: true,
      },
    ],
  },
},
{
  path: '/master/domain/bisnis/ubah',
  name: 'master-domain-proses-bisnis-ubah',
  component: () => import('@/views/master-data/MasterDomainProsesBisnis.vue'),
  meta: {
    pageTitle: 'Master Data Domain Proses Bisnis',
    breadcrumb: [
      {
        text: 'Master Data',
      },
      {
        text: 'Domain',
      },
      {
        text: 'Proses Bisnis',
      },
      {
        text: 'Ubah',
        active: true,
      },
    ],
  },
},
{
  path: '/master/domain/layanan',
  name: 'master-domain-layanan',
  component: () => import('@/views/master-data/MasterDomainLayanan.vue'),
  meta: {
    pageTitle: 'Master Data Domain Layanan',
    breadcrumb: [
      {
        text: 'Master Data',
      },
      {
        text: 'Domain',
      },
      {
        text: 'Layanan',
        active: true,
      },
    ],
  },
},
{
  path: '/master/domain/data-informasi',
  name: 'master-domain-data-informasi',
  component: () => import('@/views/master-data/MasterDomainDataInformasi.vue'),
  meta: {
    pageTitle: 'Master Data Domain Data dan Informasi',
    breadcrumb: [
      {
        text: 'Master Data',
      },
      {
        text: 'Domain',
      },
      {
        text: 'Data dan Informasi',
        active: true,
      },
    ],
  },
},
{
  path: '/master/domain/aplikasi',
  name: 'master-domain-aplikasi',
  component: () => import('@/views/master-data/MasterDomainAplikasi.vue'),
  meta: {
    pageTitle: 'Master Data Domain Aplikasi',
    breadcrumb: [
      {
        text: 'Master Data',
      },
      {
        text: 'Domain',
      },
      {
        text: 'Aplikasi',
        active: true,
      },
    ],
  },
},
{
  path: '/master/domain/infrastruktur',
  name: 'master-domain-infrastruktur',
  component: () => import('@/views/master-data/MasterDomainInfrastruktur.vue'),
  meta: {
    pageTitle: 'Master Data Infrastruktur',
    breadcrumb: [
      {
        text: 'Master Data',
      },
      {
        text: 'Domain',
      },
      {
        text: 'Infrastruktur',
        active: true,
      },
    ],
  },
},
{
  path: '/master/domain/keamanan',
  name: 'master-domain-keamanan',
  component: () => import('@/views/master-data/MasterDomainKeamanan.vue'),
  meta: {
    pageTitle: 'Master Data Domain Keamanan',
    breadcrumb: [
      {
        text: 'Master Data',
      },
      {
        text: 'Domain',
      },
      {
        text: 'Keamanan',
        active: true,
      },
    ],
  },
},
{
  path: '/master/template/bisnis',
  name: 'master-template-bisnis',
  component: () => import('@/views/master-template/TemplateProgram.vue'),
  meta: {
    pageTitle: 'Template Rekomendasi Proses Bisnis',
    breadcrumb: [
      {
        text: 'Master Data',
      },
      {
        text: 'Template Rekomendasi',
      },
      {
        text: 'Proses Bisnis',
        active: true,
      },
    ],
  },
},
{
  path: '/master/template/dokumen',
  name: 'master-template-dokumen',
  component: () => import('@/views/master-template/TemplateDokumen.vue'),
  meta: {
    pageTitle: 'Template Rekomendasi Dokumen',
    breadcrumb: [
      {
        text: 'Master Data',
      },
      {
        text: 'Template Rekomendasi',
      },
      {
        text: 'Dokumen',
        active: true,
      },
    ],
  },
},
{
  path: '/master/template/layanan',
  name: 'master-template-layanan',
  component: () => import('@/views/master-template/TemplateLayanan.vue'),
  meta: {
    pageTitle: 'Template Rekomendasi Layanan',
    breadcrumb: [
      {
        text: 'Master Data',
      },
      {
        text: 'Template Rekomendasi',
      },
      {
        text: 'Layanan',
        active: true,
      },
    ],
  },
},
{
  path: '/master/template/data-informasi',
  name: 'master-template-data-informasi',
  component: () => import('@/views/master-template/TemplateData.vue'),
  meta: {
    pageTitle: 'Template Rekomendasi Data dan Informasi',
    breadcrumb: [
      {
        text: 'Master Data',
      },
      {
        text: 'Template Rekomendasi',
      },
      {
        text: 'Data dan Informasi',
        active: true,
      },
    ],
  },
},
{
  path: '/master/template/keamanan',
  name: 'master-template-keamanan',
  component: () => import('@/views/master-template/TemplateKeamanan.vue'),
  meta: {
    pageTitle: 'Template Rekomendasi Keamanan',
    breadcrumb: [
      {
        text: 'Master Data',
      },
      {
        text: 'Template Rekomendasi',
      },
      {
        text: 'Keamanan',
        active: true,
      },
    ],
  },
},
{
  path: '/master/template/non-teknis',
  name: 'master-template-non-teknis',
  component: () => import('@/views/master-template/TemplateNon.vue'),
  meta: {
    pageTitle: 'Template Rekomendasi Non Teknis',
    breadcrumb: [
      {
        text: 'Master Data',
      },
      {
        text: 'Template Rekomendasi',
      },
      {
        text: 'Non Teknis',
        active: true,
      },
    ],
  },
},
{
  path: '/master/template/aplikasi',
  name: 'master-template-aplikasi',
  component: () => import('@/views/master-template/TemplateAplikasi.vue'),
  meta: {
    pageTitle: 'Template Rekomendasi Aplikasi',
    breadcrumb: [
      {
        text: 'Master Data',
      },
      {
        text: 'Template Rekomendasi',
      },
      {
        text: 'Aplikasi',
        active: true,
      },
    ],
  },
},
{
  path: '/master/template/infrastruktur',
  name: 'master-template-infrastruktur',
  component: () => import('@/views/master-template/TemplateInfrastruktur.vue'),
  meta: {
    pageTitle: 'Master Template Rekomendasi Infrastruktur',
    breadcrumb: [
      {
        text: 'Master Data',
      },
      {
        text: 'Template Rekomendasi',
      },
      {
        text: 'Infrastruktur',
        active: true,
      },
    ],
  },
},
{
  path: '/master/group/belanja',
  name: 'master-group-belanja',
  component: () => import('@/views/master-data/TemplateJenisBelanja.vue'),
  meta: {
    pageTitle: 'Master Jenis Belanja',
    breadcrumb: [
      {
        text: 'Master Data',
      },
      {
        text: 'Jenis Belanja',
        active: true,
      },
    ],
  },
},
{
  path: '/master/group/pengadaan',
  name: 'master-group-pengadaan',
  component: () => import('@/views/master-data/TemplateJenisPengadaan.vue'),
  meta: {
    pageTitle: 'Master Kriteria Belanja',
    breadcrumb: [
      {
        text: 'Master Data',
      },
      {
        text: 'Kriteria Belanja',
        active: true,
      },
    ],
  },
},
{
  path: '/master/group/sumber-dana',
  name: 'master-group-sumber-dana',
  component: () => import('@/views/master-data/TemplateSumberDana.vue'),
  meta: {
    pageTitle: 'Master Sumber Dana',
    breadcrumb: [
      {
        text: 'Master Data',
      },
      {
        text: 'Sumber Dana',
        active: true,
      },
    ],
  },
},
{
  path: '/master/group/status-kegiatan',
  name: 'master-status-kegiatan',
  component: () => import('@/views/master-data/TemplateStatusKegiatan.vue'),
  meta: {
    pageTitle: 'Master Status Kegiatan',
    breadcrumb: [
      {
        text: 'Master Data',
      },
      {
        text: 'Status Kegiatan',
        active: true,
      },
    ],
  },
},
]
