export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/logout',
    name: 'auth-logout',
    component: () => import('@/views/pages/authentication/Logout.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/profile',
    name: 'pages-profile',
    component: () => import('@/views/pages/Profile.vue'),
    meta: {
      pageTitle: 'Profile',
      breadcrumb: [
        {
          text: 'Profile',
          active: true,
        },
      ],
    },
  },
  {
    path: '/search',
    name: 'pages-search',
    component: () => import('@/views/pages/Search.vue'),
    meta: {
      pageTitle: 'Pencarian',
      breadcrumb: [
        {
          text: 'Pencarian',
          active: true,
        },
      ],
    },
  },
  {
    path: '/faq',
    name: 'pages-faq',
    component: () => import('@/views/pages/Faq.vue'),
    meta: {
      pageTitle: 'Pusat Bantuan',
      breadcrumb: [
        {
          text: 'FAQ',
          active: true,
        },
      ],
    },
  },
  {
    path: '/helpdesk',
    name: 'pages-helpdesk',
    component: () => import('@/views/pages/Helpdesk.vue'),
    meta: {
      pageTitle: 'Pusat Bantuan',
      breadcrumb: [
        {
          text: 'Helpdesk',
          active: true,
        },
      ],
    },
  },
  {
    path: '/helpdesk/:id(\\d+)+',
    name: 'pages-helpdesk-detail',
    component: () => import('@/views/pages/HelpdeskDetail.vue'),
    meta: {
      pageTitle: 'Pusat Bantuan',
      breadcrumb: [
        {
          text: 'Helpdesk',
          to: '/helpdesk',
        },
        {
          text: 'Pertanyaan Pengguna',
          active: true,
        },
      ],
    },
  },
  {
    path: '/reports',
    name: 'pages-reports',
    component: () => import('@/views/pages/Report.vue'),
    meta: {
      pageTitle: 'Reports',
      breadcrumb: [
        {
          text: 'Reports',
          active: true,
        },
      ],
    },
  },
]
